import React from "react";
import { SideBar } from "./sidebar";
import { getLiveActivity } from "../../models/auctions";
//import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import Countdown from "react-countdown";
import confetti from "canvas-confetti";

let lang = localStorage.getItem("I18N_LANGUAGE");
var rendCount = 0;
const FinishBid = () => (
  <h2 className="mb-1 font-weight-bold text-uppercase text-white">
    Congratulations for all the Winners !!!
  </h2>
);

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    if (rendCount < 1) {
      rendCount = rendCount + 1;
      startConfetti(5000);
    }
    return <FinishBid />;
  } else {
    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return (
      <div id="clock-c" className="countdown activity-clock">
        <span className="">{days} : </span> {/*i18n.t("days")*/}
        <span className="">{hours} : </span> {/*i18n.t("hours")*/}
        <span className="">{minutes} : </span> {/*i18n.t("minutes")*/}
        <span className="">{seconds}</span> {/*i18n.t("seconds")*/}
      </div>
    );
  }
};

var defaults = { startVelocity: 10, spread: 500, ticks: 250, zIndex: 0 };
function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function startConfetti(duration) {
  const interval = 500; // Interval in milliseconds (e.g., 500ms = 0.5 seconds)
  const intervalId = setInterval(showFireWorks, interval);

  // Stop the confetti after the specified duration
  setTimeout(() => {
    clearInterval(intervalId);
  }, duration);
}

function showFireWorks() {
  var particleCount = 150;
  // since particles fall down, start a bit higher than random
  confetti(
    Object.assign({}, defaults, {
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    })
  );
  confetti(
    Object.assign({}, defaults, {
      particleCount,
      origin: { x: randomInRange(0.4, 0.5), y: Math.random() - 0.5 },
    })
  );
  confetti(
    Object.assign({}, defaults, {
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    })
  );
}

export class LiveActivity extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: true,
      end_date: null,
      data: {},
      has_data: true,
      is_data_loading: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.loadLiveActivity = this.loadLiveActivity.bind(this);
  }

  componentDidMount() {
    this.loadLiveActivity();
    setInterval(this.loadLiveActivity, 10000);
    window.scrollTo(0, 0);
    document.getElementById("main-top-header").style.display = "none";
    document.getElementById("footer-sec").classList.add("live-footer");
  }

  async loadLiveActivity() {
    this.setState({ is_data_loading: true });
    const response = await getLiveActivity();
    if (response.success) {
      let data = response.data;
      let end_date = response.data.group.end_date;
      this.setState({ data: data, end_date: end_date });
      this.setState({ is_data_loading: false });
    }
    if (!response.success) {
      this.setState({ has_data: false });
      this.setState({ is_data_loading: false });
      //swal("Failed", response.message, "error");
    }
  }

  componentWillUnmount() {
    document.getElementById("main-top-header").style.display = "";
  }

  changeDateFormat(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let data = this.state.data;
    let group = data.group;
    let now = this.state.now;
    let top_5_biddings = data.top_5_biddings;
    let endTime = new Date(
      this.changeDateFormat(this.state.end_date)
    ).getTime();

    let bidStyle = {
      position: "absolute",
      right: "2rem",
      bottom: "0.1rem",
      fontSize: "2.5rem",
      fontWeight: "bold",
    };
    if (lang === "ar") {
      bidStyle = {
        position: "absolute",
        left: "2rem",
        bottom: "0.1rem",
        fontSize: "2.5rem",
        fontWeight: "bold",
      };
    }
    return (
      <React.Fragment>
        <section className="mt-1 mb-1 activity-bg">
          <div className="inner">
            <div className="floatingMenu">
              <button
                className="btn btn-primary border border-danger btn-rounded btn-icon floatingMenuBtn"
                onClick={(e) => {
                  this.setState({ hide_menu: !this.state.hide_menu });
                }}
              >
                {!this.state.hide_menu && <i className="fa fa-minus"></i>}
                {this.state.hide_menu && <i className="fa fa-bars"></i>}
              </button>
            </div>

            <div className="mt-1 mb-1 p-3 d-flex justify-content-between">
              <img
                src="../assets/images/mzadcomlogo.png"
                style={{ width: "70px", height: "45px" }}
                alt=""
              />
              <img
                src="../assets/images/logo.png"
                style={{ width: "50px" }}
                alt=""
              />
            </div>
            <div className="container">
              <div className="row">
                <div
                  className={
                    this.state.hide_menu
                      ? "d-none"
                      : "col-xl-3 col-lg-12 col-md-12"
                  }
                >
                  <SideBar />
                </div>
                {this.state.has_data && (
                  <div
                    className={
                      this.state.hide_menu
                        ? "col-xl-12 col-lg-12 col-md-12"
                        : "col-xl-9 col-lg-12 col-md-12"
                    }
                  >
                    <div className="row mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                        {/* <h2 className="mb-5 font-weight-bold text-uppercase ">
                        {group && group.group_name}
                      </h2> */}
                        <div>
                          {endTime && (
                            <Countdown
                              date={endTime}
                              renderer={renderer}
                              now={now}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="">
                          <div className="rounded text-white shadow pt-5 pb-5 text-center mb-5">
                            <div className="row mt-2">
                              <div className="col-xl-12 col-lg-12 col-md-12 text-centered">
                                <div className="text-center">
                                  <button
                                    className="btn btn-outline-light btn-rounded btn-lg"
                                    style={{ fontSize: "2rem" }}
                                  >
                                    <b>
                                      {i18n.t("omr")}{" "}
                                      {!this.state.is_data_loading && data
                                        ? data.largest_amoount
                                        : ""}
                                      {this.state.is_data_loading && (
                                        <Spinner animation="border" size="sm" />
                                      )}
                                    </b>
                                  </button>

                                  <span className="text-white" style={bidStyle}>
                                    {!this.state.is_data_loading && data
                                      ? data.total_bids
                                      : ""}
                                    {this.state.is_data_loading && (
                                      <Spinner animation="border" size="sm" />
                                    )}{" "}
                                    {i18n.t("Bids")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="mb-7">
                          <div className="text-white shadow pt-2 pb-5 text-center mb-5">
                            {/* rounded bg-gradient-3 */}
                            <div
                              className={
                                this.state.hide_menu
                                  ? "d-flex justify-content-between gap-1 flex-wrap"
                                  : "d-flex flex-wrap"
                              }
                            >
                              {top_5_biddings &&
                                top_5_biddings.length > 0 &&
                                top_5_biddings.map((item, index) => {
                                  return (
                                    <div className="border border-light">
                                      {/* <button className="btn btn-outline-primary btn-lg btn-rounded mb-2">
                                      {index + 1}
                                    </button> */}
                                      <button
                                        className="btn text-white btn-rounded btn-lg"
                                        style={{ fontSize: "2.5rem" }}
                                      >
                                        <span>
                                          <span>
                                            <b>{item.number}</b>
                                          </span>{" "}
                                          <br />
                                          <span>
                                            {i18n.t("omr")} {item.amount}
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ height: "2rem" }}>
                      <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                        {this.state.is_data_loading && (
                          <div>
                            <Spinner animation="grow" variant="primary" />
                            &nbsp;
                            <Spinner animation="grow" variant="primary" />
                            &nbsp;
                            <Spinner animation="grow" variant="primary" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.has_data && (
                  <div
                    className={
                      this.state.hide_menu
                        ? "col-xl-12 col-lg-12 col-md-12"
                        : "col-xl-9 col-lg-12 col-md-12"
                    }
                  >
                    <div className="rounded bg-gradient-3 text-white shadow pt-5 pb-5 text-center mb-5">
                      <h2 className="mb-5 font-weight-bold text-uppercase">
                        No active auctions found!!!
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
