import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import profilePic from "../../profilePic.jpeg";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;

function logout() {
  localStorage.clear();
  window.location.href = "/";
}

export class SideBar extends React.Component {
  render() {
    return (
      <React.Fragment>
        {is_logged && (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{i18n.t("dashboard")}</h3>
            </div>
            <div className="card-body text-center item-user border-bottom">
              <div className="profile-pic">
                <div className="profile-pic-img">
                  <span
                    className="bg-success dots"
                    data-bs-toggle="tooltip"
                    data-placement="top"
                    title="online"
                  ></span>
                  <img src={profilePic} className="brround" alt="user" />
                </div>
                <a href={true} className="text-dark">
                  <h4 className="mt-3 mb-0 font-weight-semibold">
                    {user.name}
                  </h4>
                </a>
              </div>
            </div>
            <div className="item1-links  mb-0">
              {(user.role === "A" ||
                user.role === "VA" ||
                user.role === "U") && (
                <NavLink
                  activeClassName="active"
                  to="/dashboard"
                  className="d-flex border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-user"></i>
                  </span>{" "}
                  {i18n.t("dashboard")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <>
                  <NavLink
                    to="/live_activity/special"
                    activeClassName="active"
                    className="d-flex  border-bottom"
                    target="_new"
                  >
                    <span className="icon1 me-3">
                      <i className="fa fa-desktop"></i>
                    </span>{" "}
                    {i18n.t("live_activity")}({i18n.t("special")})
                  </NavLink>
                  <NavLink
                    to="/live_activity"
                    activeClassName="active"
                    className="d-flex  border-bottom"
                    target="_new"
                  >
                    <span className="icon1 me-3">
                      <i className="fa fa-desktop"></i>
                    </span>{" "}
                    {i18n.t("live_activity")}
                  </NavLink>
                </>
              )}
              <NavLink
                activeClassName="active"
                to="/profile"
                className="d-flex border-bottom"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-user"></i>
                </span>{" "}
                {i18n.t("edit_profile")}
              </NavLink>
              {user.role === "A" && (
                <NavLink
                  to="/users"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-users"></i>
                  </span>{" "}
                  {i18n.t("users")}
                </NavLink>
              )}
              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/enrolled_users"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-users"></i>
                  </span>{" "}
                  {i18n.t("enrolled_users")}
                </NavLink>
              )}
              {user.role === "A" && (
                <NavLink
                  to="/groups"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-object-group"></i>
                  </span>{" "}
                  {i18n.t("groups")}
                </NavLink>
              )}
              {/* {user.role === "A" && (
                <NavLink
                  to="/masks"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-layers"></i>
                  </span>{" "}
                  {i18n.t("masks")}
                </NavLink>
              )} */}
              {user.role === "A" && (
                <NavLink
                  to="/create_auction"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-plus"></i>
                  </span>{" "}
                  {i18n.t("create_auction")}
                </NavLink>
              )}
              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/auctions_list"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-diamond"></i>
                  </span>{" "}
                  {i18n.t("auctions")}
                </NavLink>
              )}
              {user.role === "U" && (
                <div>
                  <NavLink
                    activeClassName="active"
                    to="/my_auctions"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="icon icon-list"></i>
                    </span>{" "}
                    {i18n.t("my_bids")}
                  </NavLink>

                  <NavLink
                    activeClassName="active"
                    to="/enrolled_auctions"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="icon icon-folder-alt"></i>
                    </span>{" "}
                    {i18n.t("enrolled_auctions")}
                  </NavLink>

                  <NavLink
                    activeClassName="active"
                    to="/winning_bids"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="icon icon-trophy"></i>
                    </span>{" "}
                    {i18n.t("winning_bids")}
                  </NavLink>
                </div>
              )}
              <NavLink
                activeClassName="active"
                to="/payments"
                className="d-flex  border-bottom"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-credit-card"></i>
                </span>{" "}
                {i18n.t("payment")}
              </NavLink>

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/reports_enrolled"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-book"></i>
                  </span>{" "}
                  {i18n.t("reports")} - {i18n.t("registraion")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/reports"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-chart"></i>
                  </span>{" "}
                  {i18n.t("reports")} - {i18n.t("bidders")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/reports_winners"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-trophy"></i>
                  </span>{" "}
                  {i18n.t("reports")} - {i18n.t("winners")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/reports_positions"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-map-marker"></i>
                  </span>{" "}
                  {i18n.t("reports")} - {i18n.t("positions")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/reports_payments"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-money"></i>
                  </span>{" "}
                  {i18n.t("reports")} - {i18n.t("payments")}
                </NavLink>
              )}

              {(user.role === "A" || user.role === "VA") && (
                <NavLink
                  to="/bulk_mail"
                  activeClassName="active"
                  className="d-flex  border-bottom"
                >
                  <span className="icon1 me-3">
                    <i className="fa fa-envelope"></i>
                  </span>{" "}
                  {i18n.t("Bulk Mails")}
                </NavLink>
              )}
              {/* <a href={true} className="d-flex border-bottom">
                <span className="icon1 me-3">
                  <i className="icon icon-settings"></i>
                </span>{" "}
                {i18n.t("settings")}
              </a> */}
              <a
                href={true}
                onClick={logout}
                style={{ cursor: "pointer" }}
                className="d-flex"
              >
                <span className="icon1 me-3">
                  <i className="icon icon-power"></i>
                </span>{" "}
                {i18n.t("logout")}
              </a>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
