import React from "react";
import { HomeBanner } from "./homeBanner";
import { AuctionListing } from "./auctionListing";
//import { EnrollChecks } from "./enrollChecks";
import { getLoggedUser } from "../../state/user";
// import { Testimonials } from "./testimonials";
import { BeingPart } from "./beignpart";
import { checkUserActiveGroups } from "../../models/users";
import { getSettingsByName } from "../../models/settings";

const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
let lang = localStorage.getItem("I18N_LANGUAGE");
export class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {
      is_enrolled: false,
      is_registration_ends: false,
      groups: [],
      home_banner_text: "No active groups/auctions at this moment!!!",
    };
  }

  componentDidMount() {
    this.checkUserActiveGroups();
    this.getSettings();
  }

  async checkUserActiveGroups() {
    if (is_logged) {
      const response = await checkUserActiveGroups();
      this.setState({
        groups: response.data,
        is_enrolled: response.meta.is_enrolled,
        is_registration_ends: response.meta.is_registration_ends,
      });
    }
  }

  async getSettings() {
    const response = await getSettingsByName("homepage_banner_text");
    this.setState({
      home_banner_text:
        lang === "ar" ? response.data.value_ar : response.data.value_en,
    });
  }

  render() {
    let is_enrolled = this.state.is_enrolled;
    let groups = this.state.groups;
    //let is_registration_ends = this.state.is_registration_ends;
    // let error_message = "We are sorry, the registration is closed. ";
    // if (lang === "ar") {
    //   error_message = "نعتذر تم اغلاق فترة التسجيل. ";
    // }
    let have_active_registrations = this.props.have_active_registrations;
    let active_group = this.props.active_group;
    let user_role = "";
    if (user && user.length) {
      user_role = user.role;
    }
    return (
      <div>
        <HomeBanner />
        {!is_logged && (
          <BeingPart
            have_active_registrations={have_active_registrations}
            active_group={active_group}
          />
        )}

        {/* {is_logged &&
          user.role === "U" &&
          !is_enrolled &&
          !is_registration_ends &&
          groups.length > 0 && <EnrollChecks groups={this.state.groups} />} */}

        {/* {is_logged &&
          user.role === "U" &&
          !is_enrolled &&
          is_registration_ends &&
          groups.length > 0 && (
            <section className="sptb bg-white">
              <div className="container">
                <div className="row mb-8 mt-8">
                  <h2
                    className="text-danger text-center mb-7 mt-7"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {error_message}
                  </h2>
                </div>
              </div>
            </section>
          )} */}

        {is_logged && groups.length > 0 && (
          <AuctionListing user_role={user_role} is_enrolled={is_enrolled} />
        )}

        {!is_logged && <AuctionListing user_role="U" is_enrolled={false} />}

        {is_logged && groups.length < 1 && (
          <section className="">
            <div className="container p-5 mt-8 mb-8">
              <div className="row mb-2 mt-2 bg-white">
                <h2
                  className="text-theme text-center mb-7 mt-7"
                  style={{ fontSize: "1.5rem" }}
                >
                  {this.state.home_banner_text}
                </h2>
              </div>
            </div>
          </section>
        )}

        {/*!is_logged && <Testimonials />} */}
      </div>
    );
  }
}
