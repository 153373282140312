import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  getAuctions,
  deleteAuction,
  getAllBidders,
  maskClasses,
  statuses,
} from "../../models/auctions";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
export class AuctionsList extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      auctions: {},
      is_auctions_loading: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      groups: {},
      query: "",
      open_popup: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e60000",
            color: "#FFFFFF",
          },
        },
      },
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "65px",
          cell: (row) => <a href={"/edit_auction/" + row.id}>{row.id}</a>,
        },
        {
          name: i18n.t("phone_number"),
          button: true,
          cell: (row) =>
            user.role === "A" ? (
              <a
                href={"/edit_auction/" + row.id}
                className="btn btn-info btn-sm"
              >
                {row.phone_number}
              </a>
            ) : (
              row.phone_number
            ),
        },
        {
          name: i18n.t("bid_count"),
          button: true,
          cell: (row) => (
            <button
              className="btn btn-success"
              disabled={row.bid_count > 0 ? false : true}
              onClick={(event) => {
                this.state.open_popup = true;
                this.openBidPopup(row.id);
              }}
            >
              {row.bid_count}
            </button>
          ),
        },
        {
          name: i18n.t("status"),
          selector: "status",
          sortable: true,
          cell: (row) => statuses[row.status],
        },
        {
          name: i18n.t("package"),
          selector: "class",
          sortable: true,
          cell: (row) => i18n.t(maskClasses[row.class].toLowerCase()),
          conditionalCellStyles: [
            {
              when: (row) => row.class === "D",
              style: {
                backgroundColor: "rgb(241 245 253)",
                color: "black",
                "&:hover": {
                  cursor: "pointer",
                },
              },
            },
            {
              when: (row) => row.class === "G",
              style: {
                backgroundColor: "rgba(248, 148, 6, 0.9)",
                color: "white",
                "&:hover": {
                  cursor: "pointer",
                },
              },
            },
            {
              when: (row) => row.calories === "S",
              style: {
                backgroundColor: "rgba(242, 38, 19, 0.9)",
                color: "white",
                "&:hover": {
                  cursor: "not-allowed",
                },
              },
            },
          ],
        },
        {
          name: i18n.t("price"),
          selector: "start_amount",
        },
        {
          name: i18n.t("incr_amount"),
          selector: "bid_increment",
          wrap: true,
        },
        {
          name: i18n.t("start_date"),
          selector: "start_date",
          wrap: true,
        },
        {
          name: i18n.t("end_date"),
          selector: "end_date",
          wrap: true,
        },
        {
          id: 10,
          name: i18n.t("invoice"),
          button: true,
          wrap: true,
          cell: (row) =>
            row.winner_file && (
              <div className="d-flex">
                <a
                  className="btn btn-primary btn-sm"
                  href={row.winner_file.link_ar}
                  target="_new"
                >
                  {i18n.t("Ar")}
                </a>
                &nbsp;
                <a
                  className="btn btn-dark btn-sm"
                  href={row.winner_file.link_en}
                  target="_new"
                >
                  {i18n.t("En")}
                </a>
              </div>
            ),
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();

    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllAuctions(qstring);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  deleteRow(id) {
    const response = deleteAuction(id);
    console.log(response);
    swal("Success", "Auction deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  async openBidPopup(auction) {
    const response = await getAllBidders({ auction });
    let bidders = response.data;
    if (bidders && bidders.length > 0) {
      this.setState({ open_popup: true, bidders });
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  handleSubmit() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllAuctions(qstring);
  }

  async loadAllAuctions(q) {
    this.setState({ is_auctions_loading: true });
    const response = await getAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ is_auctions_loading: false });
      this.setState({ auctions: auctions });
      this.setState({ totalRows: meta.total });
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllAuctions(qstring);
    console.log(page);
  };

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    return (
      <React.Fragment>
        <Modal
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("bidders")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.bidders && this.state.bidders.length > 0 && (
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th>{i18n.t("name")}</th>
                    <th>{i18n.t("rank")}</th>
                    <th>{i18n.t("amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bidders.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {item.name}
                          <br />
                          <small className="text text-dark">{item.email}</small>
                          <br />
                          <small className="text text-dark">
                            {item.mobile}
                          </small>
                        </td>
                        <td>
                          <span className="badge badge-pill badge-info me-2">
                            {i + 1}
                          </span>
                        </td>
                        <td>{item.bid_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Modal.Body>
        </Modal>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("dashboard")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("auctions")}</Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <h3
                      className="card-title"
                      onClick={() => {
                        this.setState({ hide_menu: !this.state.hide_menu });
                      }}
                    >
                      <span>
                        <i
                          class={
                            this.state.hide_menu
                              ? "fa fa-caret-right"
                              : "fa fa-caret-left"
                          }
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </span>
                      {i18n.t("auctions")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12 col-md-3">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("group")}
                                </label>
                                <select
                                  className="form-control"
                                  data-placeholder="Select Group"
                                  name="group"
                                  value={this.state.input.group}
                                  onChange={this.handleChange}
                                >
                                  <option value="">
                                    --{i18n.t("group")}--
                                  </option>
                                  {groups.length > 0 &&
                                    groups.map((item) => {
                                      return (
                                        <option value={item.id} key={item.id}>
                                          {item.group_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("package")}
                                </label>
                                <select
                                  className="form-control"
                                  data-placeholder="Select Class"
                                  name="class"
                                  value={this.state.input.class}
                                  onChange={this.handleChange}
                                >
                                  <option value="">
                                    --{i18n.t("package")}--
                                  </option>
                                  <option value="D">Diamond</option>
                                  <option value="G">Gold</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-2">
                              <div className="form-group">
                                <label className="form-label">Status</label>
                                <select
                                  className="form-control"
                                  data-placeholder="Change Status"
                                  name="status"
                                  value={this.state.input.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Status--</option>
                                  {Object.keys(statuses).map(function (key) {
                                    return (
                                      <option key={key} value={key}>
                                        {statuses[key]}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-3">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("phone_number")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone"
                                  name="phone_number"
                                  value={this.state.input.phone_number}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-2">
                              <div className="form-group">
                                <label className="form-label">&nbsp;</label>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  onClick={this.handleSubmit}
                                >
                                  {i18n.t("search")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.is_auctions_loading && (
                      <div className="text-center">
                        <Spinner animation="border" />
                      </div>
                    )}
                    <div className="table-responsive ">
                      {auctions.length < 1 && (
                        <div className="alert alert-danger">
                          No results found!!!
                        </div>
                      )}
                      {auctions.length > 0 && (
                        <DataTable
                          title=""
                          fixedHeader
                          columns={this.state.columns}
                          data={auctions}
                          customStyles={this.state.customStyles}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={this.state.totalRows}
                          paginationPerPage={15}
                          paginationDefaultPage={this.state.currentPage}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Auctions per page:",
                            rangeSeparatorText: "out of",
                          }}
                          onChangePage={this.handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
